import React, { useEffect, useState } from "react";
import { Row, Col, Tab, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useMyContext } from "../../../../../Context/MyContextProvider";
import axios from "axios";
import BookingList from "./BookingList";
const Bookings = () => {
    const { api, UserData, authToken } = useMyContext();
    const [bookings, setBookings] = useState([])


    const getBookings = async () => {
        await axios.get(`${api}user-bookings/${UserData?.id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((res) => {
                if (res.data.status) {
                    let data = res.data.bookings;
                    const filteredBookings = data.filter(booking =>
                        booking.bookings && Array.isArray(booking.bookings) && booking.bookings.length > 0
                    );
                    const normalBooking = data.filter(booking => !booking.bookings)
                    const allBookings = [...filteredBookings, ...normalBooking];
                    allBookings.sort((a, b) => {
                        const dateA = new Date(a.created_at);
                        const dateB = new Date(b.created_at);
                        return dateB.getTime() - dateA.getTime();
                    });
                    setBookings(allBookings);
                }
            }).catch((err) =>
                console.log(err)
            )
    }

    useEffect(() => {
        getBookings()
    }, [])

    return (
        <>
            <Row>
                <Col lg="8">
                    <Card>
                        <Card.Header>
                            <h4 className="card-title">Booking History</h4>
                        </Card.Header>
                        <Card.Body>
                            <Tab.Container defaultActiveKey="first">

                                <Tab.Content>
                                    <Tab.Pane
                                        eventKey="first"
                                        id="order"
                                        role="tabpanel"
                                        aria-labelledby="order-tab"
                                    >
                                        <BookingList bookings={bookings} />
                                        <div className="col-12 text-center">
                                            <Link to="#" className="btn btn-primary">
                                                Load More
                                            </Link>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        eventKey="second"
                                        id="adress"
                                        role="tabpanel"
                                        aria-labelledby="adress-tab"
                                    >
                                        <Row>
                                            <div className="col-12">
                                                <div className="address-card">
                                                    <div className="border border-3 border-dashed p-2 mb-4">
                                                        <Link
                                                            to="#"
                                                            className="d-flex align-items-center justify-content-center"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                className="text-gray icon-20"
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M12.6035 27H17.3787V17.3787H27V12.6036H17.3787V3H12.6035V12.6036H3V17.3787H12.6035V27Z"
                                                                    fill="currentColor"
                                                                ></path>
                                                            </svg>
                                                            <h6 className="ms-2 mb-0">Add Address</h6>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        eventKey="thrid"
                                        id="card"
                                        role="tabpanel"
                                        aria-labelledby="card-tab"
                                    >
                                        <h4 className="mb-4">Your Payment Options</h4>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
        </>
    )
}

export default Bookings
