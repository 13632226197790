import React from 'react'
import { Accordion, Card, Col, Form, Row } from 'react-bootstrap'
import PosEvents from '../../POS/PosEvents'

const EventAccordionAgentPOS = ({handleButtonClick,activeKey,setActiveKey}) => {
  return (
    <Card>
    <Card.Body className="py-0">
        <Accordion flush className="p-0" activeKey={activeKey} onSelect={(e) => setActiveKey(e)}>
            <Accordion.Item eventKey="0" className="bg-transparent">
                <Accordion.Header>Events</Accordion.Header>
                <Accordion.Body className="bg-transparent p-0 pt-3">
                    <Row>
                        <Col lg="12">
                            <Form>
                                <Row>
                                    <Col lg="12">
                                        <Form.Group className="mb-3 form-group input-group search-input w-100">
                                            <input
                                                type="search"
                                                className="form-control"
                                                placeholder="Search Your Event..."
                                            />
                                            <span className="input-group-text">
                                                <svg
                                                    width="18"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="11.7669"
                                                        cy="11.7666"
                                                        r="8.98856"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    ></circle>
                                                    <path
                                                        d="M18.0186 18.4851L21.5426 22"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col lg="12">
                            <PosEvents handleButtonClick={handleButtonClick} />
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </Card.Body>
</Card>
  )
}

export default EventAccordionAgentPOS
