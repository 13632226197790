import React, { memo, Fragment, useRef, useState, useEffect, useCallback } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useMyContext } from "../../../../Context/MyContextProvider";
import useDataTable from "../../../../components/hooks/useDatatable";
import CommonDateRange from "../CustomHooks/CommonDateRange";
import BookingCount from "../Events/Bookings/BookingCount";
import TableWithLoader from "../TableComp/TableWithLoader";



const PendingBookings = memo(() => {
    const { api, UserData, formatDateTime, authToken, truncateString } = useMyContext();
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dateRange, setDateRange] = useState('');

    const GetBookings = async () => {
        const queryParams = dateRange ? `?date=${dateRange}` : '';
        const url = `${api}pendding-booking/list/${UserData?.id}${queryParams}`;
        await axios.get(url, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((res) => {
                if (res.data.status) {
                    let data = res.data.bookings;
                    const filteredBookings = data.filter(booking =>
                        booking.bookings && Array.isArray(booking.bookings) && booking.bookings.length > 0
                    );
                    const normalBooking = data.filter(booking => !booking.bookings)
                    const allBookings = [...filteredBookings, ...normalBooking];
                    allBookings.sort((a, b) => {
                        const dateA = new Date(a.created_at);
                        const dateB = new Date(b.created_at);
                        return dateB.getTime() - dateA.getTime();
                    });
                    setBookings(allBookings);
                    
                }
            })
            .catch((err) => console.log(err)).finally(() => setLoading(false));
    };


    useEffect(() => {
        if (dateRange?.length > 0) {
            GetBookings();
        }
    }, [dateRange]);

    useEffect(() => {
        GetBookings();
    }, []);

    const listtableRef = useRef(null);
    const columns = useRef([
        {
            data: null,
            orderable: false,
            title: "#",
            render: (data, type, row, meta) => meta.row + 1
        },
        {
            data: null,
            title: "User Name",
            render: function (row) {
                return row?.bookings?.[0]?.user?.name || row?.user?.name || "";
            },
        },
        {
            data: null,
            title: "EasePay Id",
            render: function (row) {
                return row?.payment_log?.easepayid || "";
            },
        },
        {
            data: null,
            title: "Transaction Id",
            render: function (row) {
                return row?.payment_log?.txnid || "";
            },
        },
        {
            data: null,
            title: "Mode",
            render: function (row) {
                return row?.payment_log?.mode || "";
            },
        },
        {
            data: null,
            title: "Status",
            render: function (row) {
                const status = row?.payment_log?.status || '';
                if (status) {
                    return `<span class="badge p-1 bg-primary">${status}</span>`;
                }
                return '';
            },
        },
        {
            data: "created_at",
            title: "Purchase Date",
            render: function (data) {
                return formatDateTime(data);
            },
        },
        {
            data: null,
            title: "Event Name",
            render: function (row) {
                return `<p title=${row?.bookings?.[0]?.event_name || row?.event_name || ""}>${truncateString(row?.bookings?.[0]?.event_name || row?.event_name || "")}</p>`;
            },
        },
        {
            data: null,
            title: "Org Name",
            render: function (row) {
                return row?.bookings?.[0]?.organizer || row?.organizer || "";
            },
        },
        {
            data: null,
            title: "Action",
            render: function (row) {
                return `
                    <button class="btn btn-success pay-now py-1 m-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Ticket" data-method="PayNow" data-table="action" data-id="${row.id}">Pay Now</button>
                `;
            },
        },
        {
            data: null,
            title: "Number",
            render: function (row) {
                return row?.bookings?.[0]?.number || row?.number || "";
            },
        },
        {
            data: null,
            title: "Ticket",
            render: function (row) {
                return row?.bookings?.[0]?.ticket?.name || row?.ticket?.name || "";
            },
        },
        {
            data: null,
            title: "Qty",
            render: function (row) {
                return row?.bookings?.length || 1;
            },
        },
        {
            data: null,
            title: "Total",
            render: function (row) {
                const totalAmount = (row?.bookings && row?.bookings[0]?.amount) || row?.amount || 0;
                return `₹${totalAmount}`;
            },
        },
    ]);

    const HandlePay = useCallback(async (id) => {
        let session_id = bookings?.find(booking => booking?.id === id)?.session_id
        //console.log(session_id)
        try {
            const response = await axios.post(`${api}booking-confirm/${session_id}`, '', {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });

            if (response.data.status) {
                GetBookings()
                Swal.fire({
                    title: 'Success!',
                    text: 'Booking confirmed successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            } else {
                // Handle error response
                Swal.fire({
                    title: 'Error!',
                    text: response.data.message || 'Something went wrong.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: 'Error!',
                text: 'An error occurred while confirming the booking.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    }, [bookings, api, authToken]);
    const actionCallback = useCallback((data) => {
        switch (data.method) {
          case "PayNow":
            HandlePay(data?.id);
            break;
          default:
            break;
        }
      }, [HandlePay]);
    useDataTable({
        tableRef: listtableRef,
        columns: columns.current,
        data: bookings,
        actionCallback
    });

    if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
    }
    $("#datatable-ecom").DataTable({
        createdRow: function (row, data, dataIndex) {
            $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
    });

    


    return (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Pending Bookings</h4>
                            </div>
                            <div className="button">
                                <h4 className="card-title">
                                    <Link to={"/"}>
                                        <Button className="me-4 hvr-curl-top-right border-0">
                                            Book Ticket
                                        </Button>
                                    </Link>
                                </h4>
                            </div>
                        </Card.Header>
                        <Card.Header>
                            <Row className="d-flex align-items-center">
                                <Col sm="2">
                                    <CommonDateRange setState={setDateRange} removeClass={true} />
                                </Col>
                                <BookingCount data={bookings} />

                            </Row>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <TableWithLoader
                                ref={listtableRef}
                                loading={loading}
                                columns={columns.current}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
});

PendingBookings.displayName = "PendingBookings";
export default PendingBookings;
