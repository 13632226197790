import React, { useEffect } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import { Dashboard, Tus, Uppy } from "uppy";
import { useMyContext } from '../../../../../../../Context/MyContextProvider';
const MediaFieldset = ({
    validated,
    UpdateEvent,
    handleImageChange,
    imagepreview,
    youtubeUrl,
    loading,
    setThumbnail,
    setYoutubeUrl,
    show
}) => {
    const { ErrorAlert } = useMyContext();
    useEffect(() => {
        if (!loading) {
            const uppy = new Uppy()
                .use(Dashboard, {
                    inline: true,
                    target: '.file-uploader',
                })
                .use(Tus, { endpoint: 'https://master.tus.io/files/' });

            uppy.on('upload-success', (file) => {
                const image = new window.Image();
                if (file.size > 2 * 1024 * 1024) {
                    ErrorAlert(`File ${file.name} exceeds the 2MB size limit!`);
                    uppy.removeFile(file?.id);
                    return;
                }
                image.src = window.URL.createObjectURL(file.data);

                image.onload = function () {
                    if (file && this.width === 600 && this.height === 725) {
                        setThumbnail(file?.data)
                    } else {
                        ErrorAlert("Please upload an image with dimensions of 600x725 pixels!");
                        uppy.removeFile(file.id);
                    }
                };
            });
            return () => {
                uppy.close();
            };
        }
    }, [loading]);
    return (
        <fieldset className={`${show === "Media" ? "d-block" : "d-none"}`}>
            <Form validated={validated} onSubmit={(e) => UpdateEvent(e)} className="needs-validation" noValidate>
                <div className="form-card text-start">
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3 form-group">
                                <Form.Label className="custom-file-input">Thumbnail Image</Form.Label>
                                <div className="file-uploader"></div>
                                <span>Upload 16:9 ratio thumbnail image of atleast 600x725 px (jpg/jpeg/png)</span>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label className="custom-file-input">Images Gallery</Form.Label>
                                        <Form.Control type="file" id="customFile" multiple onChange={handleImageChange} />
                                    </Form.Group>
                                </div>
                                {imagepreview.length > 0 ?
                                    imagepreview?.map((imageUrl, index) => (
                                        <div className="col-md-3" key={index}>
                                            <Form.Group className="mb-3 form-group">
                                                <Form.Label className="custom-file-input">Image {index + 1}</Form.Label>
                                                <div className="image">
                                                    <span className="position-relative top-0 start-100 translate-middle p-1 px-2 bg-danger rounded-circle text-white">
                                                        x
                                                        <span className="visually-hidden">
                                                            unread messages
                                                        </span>
                                                    </span>
                                                    <Image src={imageUrl} alt={`Image ${index + 1}`} className="img-fluid w-100" />
                                                </div>
                                            </Form.Group>
                                        </div>
                                    ))
                                    :
                                    <>
                                        {[...Array(4)].map((_, index) => (
                                            <div className="col-md-3" key={index}>
                                                <Form.Group className="mb-3 form-group">
                                                    <Form.Label className="custom-file-input">Image {index + 1}</Form.Label>
                                                    <Image src={`https://placehold.co/200x200`} alt={`Barcode ${index + 1}`} className="img-fluid w-100" />
                                                </Form.Group>
                                            </div>
                                        ))}
                                    </>
                                }

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label">YouTube Video URL (Optional): *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="yt"
                                            value={youtubeUrl}
                                            placeholder="https://www.youtube.com/watch?v=Zjq1zRWpcgs"
                                            onChange={(e) => setYoutubeUrl(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mt-3">
                                        <Button
                                            type="submit"
                                            name=""
                                            className="action-button "
                                            value=""
                                        // onClick={() => AccountShow("Timing")}
                                        >
                                            Save & Next
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Form>
        </fieldset>
    );
};

export default MediaFieldset;
