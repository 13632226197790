import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Card, Col, Row, Form, Button, Modal } from 'react-bootstrap'
import useDataTable from '../../../../components/hooks/useDatatable';
import axios from 'axios';
import { useMyContext } from '../../../../Context/MyContextProvider';
import TableWithLoader from '../TableComp/TableWithLoader';
const Promocode = memo(() => {

    const { api, successAlert, UserData, authToken, ErrorAlert } = useMyContext();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleShow = () => setShow(true);

    const [code, setCode] = useState("");
    const [description, setDescription] = useState("");
    const [discountType, setDiscountType] = useState("");
    const [discountValue, setDiscountValue] = useState();
    const [minimumSpend, setMinimumSpend] = useState();
    const [usageLimit, setUsageLimit] = useState();
    const [usagePerUser, setUsagePerUser] = useState();
    const [status, setStatus] = useState();
    const [promocodes, setPromocodes] = useState([]);





    const getPromocodes = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${api}promo-list/${UserData?.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            setPromocodes((res.data.promoCodes).reverse())
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getPromocodes()
    }, []);


    const handleClose = () => {
        setShow(false);
        setEditState(false)
        setPromocodeId('')
        setCode("");
        setDescription("");
        setDiscountType("");
        setDiscountValue("");
        setMinimumSpend("");
        setUsageLimit("");
        setUsagePerUser("");
        setStatus();
    }

    //template

    const [editState, setEditState] = useState('')
    const [promocodeId, setPromocodeId] = useState('')


    const handleEdit = useCallback(
        async (id) => {
            const data = promocodes?.find((item) => item?.id === id);

            if (data) {
                setPromocodeId(id);
                setEditState(true);
                setCode(data.code || "");
                setDescription(data.description || "");
                setDiscountType(data.discount_type || "percentage");
                setDiscountValue(data.discount_value || "");
                setMinimumSpend(data.minimum_spend || "");
                setUsageLimit(data.usage_limit || "");
                setUsagePerUser(data.usage_per_user || "");
                setStatus(data.status === 1 ? true : false);
                setShow(true);
            }
        },
        [promocodes] // Dependency array for useCallback
    );
    const handleDelete = useCallback(async (id) => {
        try {
            const url = `${api}promo-destroy/${id}`;
            const response = await axios.delete(url, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (response.data.status) {
                getPromocodes();
                successAlert('success', 'Promo deleted successfully')
            }
        } catch (error) {
            ErrorAlert('Error deleting promo:', error);
        }
    }, [api, authToken]);


    const listtableRef = useRef(null);
    const columns = [
        {
            data: null, // No direct data mapping
            orderable: false,
            title: "#",
            render: (data, type, row, meta) => meta.row + 1 // Use index + 1 as OrderId
        },
        { data: "code", title: "Code" },
        { data: "description", title: "Description" },
        { data: "discount_type", title: "Discount Type" },
        { data: "discount_value", title: "Discount Value" },
        { data: "minimum_spend", title: "Minimum Spend" },
        { data: "usage_limit", title: "Usage Limit" },
        { data: "remaining_count", title: "Remaining Limit" },
        { data: "usage_per_user", title: "Usage Per User" },
        {
            title: "Status",
            data: "status",
            render: (status) => {
                const circleClass = status === 0 ? 'bg-danger' : 'bg-success';
                const statusText = status === 0 ? 'Disabled' : 'Active';
                return ` <span class="d-inline-block rounded-circle ${circleClass}" style="width: 12px; height: 12px;" title="${statusText}"></span>`
            }
        },
        {
            data: null,
            orderable: false,
            searchable: false,
            title: "Action",
            render: function (data) {
                return `<div class="flex align-items-center list-user-action">
                                      <button class="btn btn-sm btn-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Print" data-id=${data?.id} data-method="Edit" data-table="action">
                                         <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-20" width="20" height="32" viewBox="0 0 24 24"><path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path></svg>
                                      </button>
                                      <button class="btn btn-sm btn-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Print" data-id=${data?.id} data-method="Delete" data-table="action">
                                         <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-20" width="20" height="32" viewBox="0 0 24 24"><path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path></svg>
                                      </button>`;
            },
        },
    ];
    const actionCallback = useCallback((data) => {
        switch (data?.method) {
            case "Edit":
                handleEdit(data?.id);
                break;
            case "Delete":
                handleDelete(data?.id);
                break;
            default:
                break;
        }
    }, [handleEdit, handleDelete]);
    
    useDataTable({
        tableRef: listtableRef,
        columns: columns,
        data: promocodes,
        actionCallback
    });

    const HandleSubmit = async (e) => {
        try {
            e.preventDefault();
            const discountData = {
                code,
                description,
                discount_type: discountType,
                discount_value: discountValue,
                minimum_spend: minimumSpend,
                usage_limit: usageLimit,
                usage_per_user: usagePerUser,
                status: status === true ? 1 : 0,
            };
            if (editState) {
                discountData.id = promocodeId;
            }
            let url = editState ? `${api}promo-update` : `${api}promo-store`;
            let method = editState ? 'put' : 'post';
            const response = await axios[method](url, discountData, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (response.data.status) {
                getPromocodes()
                handleClose()
                successAlert('success', editState ? 'Promocode updated successfully' : 'Promocode added successfully')
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">{`${editState ? 'Update ' : ' Add New '}`} Promocode</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col lg="6">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg="6">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg="6">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Discount Type</Form.Label>
                                    <Form.Select
                                        value={discountType}
                                        onChange={(e) => setDiscountType(e.target.value)}
                                    >
                                        <option value="percentage">Percentage</option>
                                        <option value="fixed">Fixed</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col lg="6">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Discount Value</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={discountValue}
                                        onChange={(e) => setDiscountValue(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg="6">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Minimum Spend</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={minimumSpend}
                                        onChange={(e) => setMinimumSpend(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg="6">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Usage Limit</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={usageLimit}
                                        onChange={(e) => setUsageLimit(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg="6">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Usage Per User</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={usagePerUser}
                                        onChange={(e) => setUsagePerUser(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg="6">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <option value={true}>Active</option>
                                        <option value={false}>Inactive</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={handleClose}
                    >
                        Discard Changes
                    </Button>
                    <Button variant="primary" onClick={HandleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Card>
                <Card.Header>
                    <div className='d-flex justify-content-between'>
                        <h4 className="card-title">Promo Codes</h4>
                        <div className='d-flex justify-content-end'>
                            <Button type="button" className=""
                                onClick={() => handleShow()}
                            >New</Button>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <TableWithLoader
                        ref={listtableRef}
                        loading={loading}
                        columns={columns.current}
                    />
                </Card.Body>
            </Card>
        </>
    )
})

export default Promocode