import React, { memo, useState, useEffect, useRef } from 'react';
import { Row, Col, Card, Modal, Button, Form, Image } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import CountUp from 'react-countup';
import useSound from 'use-sound';
import QrScanner from 'qr-scanner';

import beepSound from '../../../../assets/event/stock/tik.mp3';
import errorSound from '../../../../assets/event/stock/error.mp3';
import { useMyContext } from '../../../../Context/MyContextProvider';
import BookingsAttendee from '../Events/LandingEvents/OrderComps/BookingsAttendee';

const TicketVerification = memo(({
    scanMode = 'manual',
    getUserId = (userData) => userData?.id
}) => {
    const { api, formatDateTime, authToken, UserData, fetchCategoryData } = useMyContext();
    const [QRdata, setQRData] = useState('');
    const [type, setType] = useState('');
    const [show, setShow] = useState(false);
    const [ticketData, setTicketData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [hasData, setHasData] = useState(false);
    const [autoCheck, setAutoCheck] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showAttendeee, setShowAttendee] = useState(false);
    const [attendees, setAttendees] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [play] = useSound(beepSound);
    const [error] = useSound(errorSound);
    const videoElementRef = useRef(null);

    // Fetch event ticket information
    const getEventData = async () => {
        try {
            const res = await axios.get(`${api}event-ticket-info/${getUserId(UserData)}`, {
                headers: { 'Authorization': 'Bearer ' + authToken }
            });

            if (res.data.status) {
                setEventData(res.data.data);
            }
        } catch (err) {
            console.error('Event data fetch error:', err);
        }
    };

    // Initialize event data on component mount
    useEffect(() => {
        getEventData();
    }, []);

    // Get ticket details when QR data is complete
    const getTicketDetail = async (data) => {
        try {
            const res = await axios.post(`${api}verify-ticket/${data}`,
                { user_id: UserData?.reporting_user },
                { headers: { 'Authorization': 'Bearer ' + authToken } }
            );
            if (res.data.status) {
                play();
                const mainBookings = res?.data?.bookings
                setTicketData(mainBookings);
                setType(res?.data?.type);
                const event = res.data?.event
                getCategoryData(event)
                setShowAttendee(res.data?.attendee_required === 1);
                if (res.data?.is_master) {
                    const combinedAttendees = mainBookings?.bookings
                        ?.map(booking => {
                            console.log('Attendee:', booking?.attendee);
                            return booking?.attendee;
                        })
                        ?.flat();

                    setAttendees(combinedAttendees)
                } else if (mainBookings?.attendee) {
                    setAttendees([mainBookings?.attendee])
                }
                setShow(true);
            }
        } catch (err) {
            setQRData('');
            const formattedTime = formatDateTime(err?.response?.data?.time);
            const message = err?.response?.data?.time
                ? `Check In: <strong>${formattedTime}</strong>`
                : '';

            Swal.fire({
                icon: 'error',
                title: err?.response?.data?.message,
                html: message,
                timer: 1000
            });
            error();
        }
    };


    const getCategoryData = async (event) => {
        let data = await fetchCategoryData(event?.category?.id)
        setCategoryData(data)
    }


    // Trigger ticket detail fetch when QR data reaches correct length
    useEffect(() => {
        if (QRdata?.length === 9) {
            getTicketDetail(QRdata);
        }
    }, [QRdata]);

    // Update hasData when ticket data is available
    useEffect(() => {
        setHasData((Object.entries(ticketData)?.length || ticketData?.bookings?.length) > 0);
    }, [ticketData]);

    // Verify ticket
    const handleVerify = async () => {
        if (QRdata && !isProcessing) {
            setIsProcessing(true);

            try {
                const res = await axios.get(`${api}chek-in/${QRdata}`, {
                    headers: { 'Authorization': 'Bearer ' + authToken }
                });

                if (res.data.status) {
                    Sweetalert();
                    setQRData('');
                    setShow(false);
                    getEventData();
                }
            } catch (err) {
                SweetalertError(err.response.data.message);
            } finally {
                setIsProcessing(false);
            }
        }
    };

    // Success alert
    const Sweetalert = () => {
        Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Ticket Scanned Successfully.",
            timer: 1000,
            willClose: () => setIsProcessing(false)
        });
    };

    // Error alert
    const SweetalertError = (data) => {
        Swal.fire({
            icon: "error",
            title: data,
            timer: 1000,
            willClose: () => setIsProcessing(false)
        });
    };

    // Auto-check mechanism
    useEffect(() => {
        if (show && autoCheck) {
            const timer = setTimeout(handleVerify, 1500);
            return () => clearTimeout(timer);
        }
    }, [show, autoCheck]);

    // Camera QR scanning setup
    useEffect(() => {
        if (scanMode === 'camera' && videoElementRef.current) {
            const qrScanner = new QrScanner(
                videoElementRef.current,
                (result) => {
                    if (result?.data) {
                        setQRData(result.data);
                    }
                },
                {
                    returnDetailedScanResult: true,
                    highlightScanRegion: true,
                    highlightCodeOutline: true,
                }
            );
            qrScanner.start();

            return () => {
                qrScanner.stop();
                qrScanner.destroy();
            };
        }
    }, [scanMode]);

    // Ticket details fields
    // const showdataFromAttendee = (data) => {
    //     const data = 
    // }
    const fields = [
        // ...(hasData &&
        //     (ticketData?.attendee?.Photo || ticketData?.bookings?.[0]?.attendee?.Photo)
        //     ? [
        //         {
        //             // label: "Photo",
        //             isPhoto: true,
        //             value:
        //                 ticketData?.attendee?.Photo ||
        //                 ticketData?.bookings?.[0]?.attendee?.Photo,
        //         },
        //     ]
        //     : []
        // ),
        {
            label: "Number",
            value: hasData
                ? ticketData?.number || ticketData?.bookings?.[0]?.number || ""
                : "",
        },
        {
            label: "Name",
            value: hasData
                ? ticketData?.name || ticketData?.bookings?.[0]?.name || ""
                : "",
        },
        {
            label: "Ticket",
            value: hasData
                ? ticketData?.ticket?.name || ticketData?.bookings?.[0]?.ticket?.name || ""
                : "",
        },
        {
            label: "QTY",
            value: hasData
                ? ticketData?.quantity || ticketData?.bookings?.length || 1
                : "",
        },
        {
            label: "From",
            value: hasData
                ? ticketData?.ticket?.event?.date_range?.split(",")[0] ||
                ticketData?.bookings?.[0]?.ticket?.event?.date_range?.split(",")[0] ||
                ""
                : "",
        },
        {
            label: "To",
            value: hasData
                ? ticketData?.ticket?.event?.date_range?.split(",")[1] ||
                ticketData?.bookings?.[0]?.ticket?.event?.date_range?.split(",")[1] ||
                (ticketData?.ticket?.event?.date_range?.split(",")?.length === 1
                    ? ticketData?.ticket?.event?.date_range
                    : "") ||
                (ticketData?.bookings?.[0]?.ticket?.event?.date_range?.split(",")?.length === 1
                    ? ticketData?.bookings?.[0]?.ticket?.event?.date_range
                    : "") ||
                ""
                : "",
        },
    ];


    // Camera styles (if applicable)
    const styles = scanMode === 'camera' ? {
        qrVideo: {
            objectFit: 'cover',
            width: '310px',
            height: '500px',
            borderRadius: '10px',
        }
    } : {};


    const StatsCard = ({ label, value }) => (
        <Col>
            <Card className="card-block card-stretch card-height">
                <Card.Body>
                    <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark">{label}</span>
                    </div>
                    <h2 className="counter p-0">
                        <CountUp
                            start={0}
                            end={value}
                            duration={1}
                            useEasing={true}
                            separator=","
                        />
                    </h2>
                </Card.Body>
            </Card>
        </Col>
    );


    return (
        <>
            {/* Ticket Details Modal */}
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            // centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {hasData && (ticketData?.ticket?.event?.name || ticketData?.bookings?.[0]?.ticket?.event?.name || '')} - {type}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border rounded">
                        <Card className="shadow-none mb-0">
                            <Card.Body>
                                <Row>
                                    {fields.map((field, index) => (
                                        <Col
                                            key={index}
                                            xs={field?.isPhoto ? 12 : 6}
                                            md={field?.isPhoto ? 12 : 4}
                                            className={`d-flex align-items-center gap-3 mb-3 ${field?.isPhoto && 'justify-content-center'}`}
                                        >
                                            {field?.isPhoto ? (
                                                <Image
                                                    src={field?.value}
                                                    alt="Field Image"
                                                    style={{ width: "150px", height: "150px", objectFit: "cover" }}
                                                />
                                            ) : (
                                                <>
                                                    <p className="p-0 m-0">{field?.label}:</p>
                                                    <h6 className="p-0 m-0">{field?.value}</h6>
                                                </>
                                            )}
                                        </Col>
                                    ))}
                                </Row>
                                {showAttendeee &&
                                    <BookingsAttendee
                                        attendeeList={attendees}
                                        apiData={categoryData?.customFieldsData}
                                        ShowAction={false}
                                    />
                                }
                            </Card.Body>
                        </Card>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button onClick={handleVerify}>Verify</Button>
                </Modal.Footer>
            </Modal>

            {/* Event Statistics */}
            <Row>
                <Col lg="12">
                    <Row>
                        {eventData?.map((item, index) => (
                            <Col key={index} xl={6} md={6}>
                                <Card>
                                    <Card.Header>{item?.event?.name}</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            {['Checked', 'Remaining', 'Total'].map((type) => (
                                                <StatsCard
                                                    key={type}
                                                    label={type}
                                                    value={item?.[`${type.toLowerCase()}_bookings`]}
                                                />
                                            ))}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>

                {/* Scanner Input */}
                <Col sm="12" lg="3">
                    <Card>
                        <Card.Body className="d-flex justify-content-center flex-column">
                            <div className='d-flex justify-content-end'>
                                <div className='d-flex gap-2'>
                                    <Form.Check.Label htmlFor="flexSwitchCheckDefault">
                                        Auto Check
                                    </Form.Check.Label>
                                    <Form.Check className="form-switch">
                                        <Form.Check.Input
                                            type="checkbox"
                                            className="me-2"
                                            id="flexSwitchCheckDefault"
                                            onChange={(e) => setAutoCheck(e.target.checked)}
                                        />
                                    </Form.Check>
                                </div>
                            </div>

                            {/* Conditional rendering based on scan mode */}
                            {scanMode === 'manual' ? (
                                <Form.Control
                                    type="text"
                                    value={QRdata}
                                    onChange={(e) => setQRData(e.target.value)}
                                    placeholder="QR Data"
                                    maxLength={9}
                                    autoFocus
                                />
                            ) : (
                                <video style={styles.qrVideo} ref={videoElementRef} />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
});

export default TicketVerification;